import "./styles.css";
import { Divider, Flex, Layout, Typography } from "antd";
import previewImg from "./Lincy_in_iPhone_15_Pro.png";
import { AndroidFilled } from "@ant-design/icons";
import useUrlState from "@ahooksjs/use-url-state";
import AppStoreIcon from "./app_store_black_sc.svg";
import AppDownloadLink from "./app-download-link";
import CommonFooter from "../../components/CommonFooter";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const shortHash = "5b4eae31";
const version = "3.9.2";

function getAPKPath(name: string, channel?: string) {
  if (channel) {
    return `https://cdn.mgz.cc/pub/apk/lingxi/${shortHash}/LincyTalk-v${version}-${channel}-${name}-release.apk`;
  }
  return `https://cdn.mgz.cc/pub/apk/lingxi/${shortHash}/LincyTalk-v${version}-${name}-release.apk`;
}

export default function DownloadPage() {
  const [{ channel = "Official" }] = useUrlState({ channel: "Official" });

  return (
    <Layout>
      <Content>
        <Flex vertical wrap="wrap" justify="center" align="center" gap={32}>
          <Flex vertical align="center" gap={32}>
            <Typography>
              <Title style={{ textAlign: "center" }} level={1}>
                语有灵犀
              </Title>
              <Paragraph style={{ textAlign: "center" }}>
                还在刷题背单词？
                <br />
                已经学习英语很多年，但仍然只会哑巴英语？
                <br />
                口语要靠说出来！
                <br />
                一对一AI外教，助力你听说能力UP UP UP！
              </Paragraph>
            </Typography>
            <Flex
              gap={8}
              wrap="wrap"
              justify="center"
              align="center"
              className="download-box"
            >
              <AppDownloadLink
                href="https://apps.apple.com/cn/app/%E8%AF%AD%E6%9C%89%E7%81%B5%E7%8A%80/id6470182578"
                title="App Store 下载"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="app-store"
                  src={AppStoreIcon}
                  alt="App Store 下载"
                />
              </AppDownloadLink>

              <AppDownloadLink
                href={getAPKPath("arm64-v8a", channel)}
                download
                target="_blank"
                rel="noreferrer"
              >
                <div className="android-download">
                  <AndroidFilled className="android-download-icon" />
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <div style={{ fontSize: 22, fontWeight: 500 }}>Android</div>
                    <div
                      style={{ fontSize: 13, fontWeight: 500, lineHeight: 1 }}
                    >
                      下载
                    </div>
                  </div>
                </div>
              </AppDownloadLink>
            </Flex>
            <div className="preview-box">
              <img className="preview-img" src={previewImg} alt="语有灵犀" />
            </div>
          </Flex>
          <ul className="features">
            <li>跟AI老师对话交流，上百个场景自选，社恐也敢自信练</li>
            <li>遇到生词不会说？试着中英混着聊，大胆说才能真掌握</li>
            <li>AI老师提供即时的个性化反馈，让你的发音和表达更地道</li>
            <li>基础差也不怕，资深雅思老师教研，带你练最实用的句子和词汇</li>
            <li>智能生词本，不懂的生词可收藏复习，背单词更加高效</li>
          </ul>
        </Flex>
      </Content>
      <Divider />
      <CommonFooter />
    </Layout>
  );
}
