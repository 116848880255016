export const zhHansContent = (
  <>
    <header>
      <h2>前言</h2>
      <p>
        尊敬的语有灵犀用户：广州学晟科技有限公司对《隐私协议》进行了更新，请您仔细阅读本《隐私协议》并确定了解语有灵犀对用户个人信息的处理规则。阅读过程中，如您有任何疑问，可联系语有灵犀客服进行咨询；如不同意《隐私协议》中的任何条款，您应当立即停止使用或访问语有灵犀相关服务及网站。
      </p>
      <p>
        最后更新日期：<time dateTime="2024-04-03">2024年4月3日</time>
        <br />
        生效时间：<time dateTime="2021-12-16">2021年12月16日</time>
      </p>
    </header>
    <article>
      <section>
        <h1>隐私协议</h1>
        <p>
          您的个人信息安全对广州学晟科技有限公司（以下简称“我们”）来说至关重要。我们将按照法律法规的要求，采取相应的措施保护您个人信息的安全可控。基于上述目的，我们制定本《隐私协议》并对您做出如下提示：
        </p>
        <p>
          您在使用我们的产品或服务时，我们可能会集和使用您的相关信息。我们希望通过本隐私协议向您说明我们在您使用我们的产品或服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
        </p>
        <p>
          本隐私协议与您所使用的语有灵犀相关服务以及该服务所包括的各种业务功能（以下统称“我们的产品或服务”）息息相关，希望您在使用我们的产品或服务前仔细阅读并确认您已经充分理解本条款所写明的内容，并让您可以按照本隐私协议的指引做出您认为适当的选择。您使用或在我们更新本隐私协议后（我们会及时提示您更新的情况）继续使用我们的产品或服务，即意味着您同意本隐私协议（含更新版本）内容，并且同意我们按照本隐私协议收集、使用、保存和共享您的相关信息。
        </p>
        <p>
          我们承诺会对您的个人信息和其它数据进行严格保密，并严格按照本隐私协议所阐述的内容处理您的个人信息。我们会根据您的同意和其它可处理您个人信息的法律依据收集、使用、存储、共享和转移您的个人信息。
        </p>
        <h2>一、定义</h2>
        <p>
          1.1
          个人信息：个人信息是指以电子或其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括但不限于自然人的姓名、出生日期、身份证件号码、个人生物识别信息、住址、电话号码等。
        </p>
        <p>
          1.2 个人敏感信息：
          个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损伤或歧视性待遇等的个人信息。例如，个人敏感信息包括家庭住址、个人生物识别信息、银行账号、通信内容、健康生理内容等。
        </p>
        <h2>二、我们处理个人信息的法律依据</h2>
        <p>
          如果您是中华人民共和国大陆地区的用户，我们将依据《中华人民共和国个人信息保护法》、《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》（GB
          /
          T35273-2020）以及其相关法律法规收集和使用您的个人信息，为您提供语有灵犀相关产品及服务。我们通常只会在征得您的同意的情况下收集您的个人信息，或者可能需要个人信息来保护您的重要利益或其他人的利益。
        </p>
        <h2>三、我们如何使用您的个人信息</h2>
        <p>
          3.1
          我们仅会考虑实现产品及服务功能的目的，根据合法，正当，必要的原则，收集和使用您的个人信息。
        </p>
        <p>
          3.2
          如果我们将信息用作本政策未载明的其他用途或根据特定目的收集而来的信息用于其他目的时，我们将以合理的方式通知您，并会事先征求您的同意。
        </p>
        <p>
          3.3
          我们收集您的信息是为了给您提供更好的课程体验。我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。为了方便您得到想要的服务，我们将提供“个性化推荐”的开关设置，若您不希望我们向您推荐符合您学习需求和进度的用户和动态内容，您可在设置中将此开关设置为关闭。
        </p>
        <p>
          3.4
          您在使用我们产品或服务时需通过第三方关联帐户登录，我们会请求第三方提供您的账户昵称、头像、账号、性别等信息；并且，我们需要您提供手机号码以绑定学习资格。若您拒绝提供上述信息，会导致您无法使用我们的产品或服务。
        </p>
        <p>
          3.5
          当您在参加我们的活动，填写用户调查问卷，线上助教答疑等问题时，我们可能会向您收集姓名、电话、地址、教育背景、职业、工作年限等信息，接收并记录的您的浏览器和计算机、移动设备等设备相关信息，这些信息包括：
          <ol>
            <li>
              设备型号，操作系统版本，设备设置，唯一设备标识符等软硬件特征信息等设备信息；
            </li>
            <li>
              通过GPS，蓝牙或WIFI信号接入点，蓝牙和基站传感器信息等获得的位置信息；
            </li>
            <li>
              当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们产品或服务的详细使用情况，作为相关网络日志保存。
            </li>
          </ol>
        </p>
        <p>
          3.6 在您购买课程进行支付时，我们会收集您的
          <strong>
            第三方支付账号（例如支付宝账号、微信账号、ApplePay账号或其他形式的银行卡信息）
          </strong>
          ，如果您开通了指纹支付或面部识别支付，需要使用您的
          <strong>指纹或面部信息</strong>
          进行验证，我们不会采集您的指纹或面部原图，您的指纹原图仅保存在您授权采集指纹的设备上。
        </p>
        <p>
          3.7
          注销账号方式：您可以在设置-关于-删除账号发起注销申请。我们会在收到您的注销申请后，根据您的要求，删除您的个人信息。
        </p>
        <p>
          3.8 第三方 SDK 信息收集
          <ol>
            <li>
              微信 Open SDK
              <ul>
                <li>使用目的：帮助用户使用微信账号登录</li>
                <li>使用场景：用户每次选择微信帐号登录时</li>
                <li>共享方式：SDK本机采集，不涉及数据共享</li>
                <li>第三方名称：深圳市腾讯计算机系统有限公司</li>
                <li>
                  收集/共享信息：网络访问，获取Wi-Fi状态，读取电话状态，写入外部存储，使用存储权限读取外置存储信息，设备标识信息（包括IMEI、设备序列号、Android
                  ID、MAC地址、SIM卡序列号）、已安装APP信息、传感器信息、剪切板。
                </li>
                <li>信息类型：操作系统信息、设备型号信息、应用列表信息</li>
                <li>
                  隐私协议：https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8
                </li>
              </ul>
            </li>
            <li>
              阿里云移动推送 SDK
              <ul>
                <li>使用场景：用于识别推送设备，实现向单个设备推送功能</li>
                <li>第三方名称：阿里云计算有限公司</li>
                <li>
                  收集信息：设备信息（包括操作系统、设备型号），IP ，运营商信息
                </li>
                <li>
                  隐私协议：https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html
                </li>
              </ul>
            </li>
            <li>
              闪验 SDK
              <ul>
                <li>第三方主体：上海璟梦信息科技有限公司 SDK</li>
                <li>用途：为APP用户提供一键登录、号码验证、安全校验服务 SDK</li>
                <li>信息处理类型：为了实现网关取号技术，闪验SDK</li>
                <li>
                  需要获取本机号码、设备标识符（包括IDFV、OAID）、设备硬件信息（包括设备型号、设备硬件制造商）、操作系统信息（包括操作系统版本）、网络信息（包括网络类型、运营商名称、IP地址、网络状态、网络制式）、SIM卡信息（包括SIM卡状态、subId）、应用信息（包括BundleId、包名、包签名）。
                </li>
                <li>数据处理方式：通过去标识化、加密传输及其他安全方式</li>
                <li>
                  隐私协议：https://doc.chuanglan.com/document/T98GE1KYCZDMJDHN
                </li>
              </ul>
            </li>
            <li>
              中国移动
              <ul>
                <li>
                  收集信息名称：网络类型、网络地址、运营商类型、本机号码、SIM卡状态、手机设备类型、手机操作系统、硬件厂商
                </li>
                <li>
                  目的及其用途：识别⽤户的⼿机号码快速登录/号码校验，问题查询、分析、风险控制
                </li>
                <li>信息处理方式：SDK 自行采集</li>
                <li>第三方主体：中移互联网有限公司</li>
                <li>协议名称：《中国移动认证服务条款》</li>
                <li>
                  协议链接：
                  https://wap.cmpassport.com/resources/html/contract.html
                </li>
              </ul>
            </li>
            <li>
              中国联通
              <ul>
                <li>
                  收集信息名称：网络类型、网络地址、运营商类型、本机手机号、手机设备类型、手机操作系统、硬件厂商
                </li>
                <li>目的及其用途：识别⽤户的⼿机号码快速登录/号码校验</li>
                <li>信息处理方式：SDK 自行采集</li>
                <li>第三方主体：中国联合网络通信有限公司</li>
                <li>协议名称：《中国联通服务与隐私协议条款》</li>
                <li>协议链接：https://ms.zzx9.cn/html/oauth/protocol2.html</li>
              </ul>
            </li>
            <li>
              中国电信
              <ul>
                <li>
                  收集信息名称：注册手机号码、本机号码、网络连接类型、网络状态信息、网络地址、运营商类型、手机设备类型、手机设备厂商、手机操作系统类型及版本
                </li>
                <li>
                  目的及其用途：识别⽤户的⼿机号码快速登录/号码校验及业务风控
                </li>
                <li>信息处理方式：SDK 自行采集</li>
                <li>第三方主体：天翼数字生活科技有限公司</li>
                <li>协议名称：《天翼服务及隐私协议》</li>
                <li>
                  协议链接：https://e.189.cn/sdk/agreement/detail.do?hidetop=true
                </li>
              </ul>
            </li>
            <li>
              讯飞 SDK
              <ul>
                <li>使用场景：用于语音合成</li>
                <li>第三方名称：科大讯飞股份有限公司</li>
                <li>
                  隐私协议：https://www.xfyun.cn/doc/tts/online_tts/online_tts_sdkprivacy.html
                </li>
              </ul>
            </li>
            <li>
              小米推送SDK
              <ul>
                <li>开发者名称：北京小米移动软件有限公司</li>
                <li>使用目的：用于向您推送消息</li>
                <li>
                  共享的个人信息类型：设备标识(OAID、加密的Android
                  ID)、推送消息内容、设备信息(设备厂商、型号、归属地、运营商名称等)、通知栏设置
                </li>
                <li>
                  可能调用的权限：访问网络状态、获取设备信息、写入设备存储
                </li>
                <li>
                  隐私政策链接：https://dev.mi.com/console/doc/detail?pId=1822
                </li>
              </ul>
            </li>
            <li>
              OPPO推送SDK
              <ul>
                <li>第三方名称：广东欢太科技有限公司</li>
                <li>使用目的：用于在OPPO设备上推送消息</li>
                <li>处理方式：通过去标识化、加密传输和处理的安全处理方式</li>
                <li>
                  收集个人信息类型：设备标识信息、网络信息、设备状态信息、应用使用信息
                </li>
                <li>
                  隐私政策链接：
                  https://open.oppomobile.com/new/developmentDoc/info?id=10288
                </li>
              </ul>
            </li>
            <li>
              华为推送SDK
              <ul>
                <li>第三方名称：华为软件技术有限公司</li>
                <li>使用目的：用于在华为设备上推送消息</li>
                <li>处理方式：通过去标识化、加密传输和处理的安全处理方式</li>
                <li>收集个人信息类型：应用标识信息、消息下发记录等</li>
                <li>
                  隐私政策链接：
                  https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177
                </li>
              </ul>
            </li>
            <li>
              魅族推送SDK
              <ul>
                <li>第三方名称：珠海星纪魅族信息技术有限公司</li>
                <li>使用目的：用于在魅族设备上推送消息</li>
                <li>处理方式：通过去标识化、加密传输和处理的安全处理方式</li>
                <li>
                  收集个人信息类型：设备标识信息、位置信息、网络状态信息、运营商信息
                </li>
                <li>隐私政策链接：http://open.flyme.cn/docs?id=202</li>
              </ul>
            </li>
            <li>
              VIVO推送SDK
              <ul>
                <li>第三方名称：维沃移动通信有限公司</li>
                <li>使用目的：用于在VIVO设备上推送消息</li>
                <li>处理方式：通过去标识化、加密传输和处理的安全处理方式</li>
                <li>收集个人信息类型：设备标识信息、网络状态信息</li>
                <li>
                  隐私政策链接： https://dev.vivo.com.cn/documentCenter/doc/652
                </li>
              </ul>
            </li>
            <li>
              TikTok Business SDK
              <ul>
                <li>第三方名称：字节跳动科技有限公司</li>
                <li>使用目的：收集、分析匿名使用数据</li>
                <li>处理方式：通过去标识化、加密传输和处理的安全处理方式</li>
                <li>
                  收集个人信息类型：设备标识信息、网络状态信息、谷歌广告
                  ID(GAID)
                </li>
                <li>
                  隐私政策链接：https://ads.tiktok.com/i18n/official/policy/business-products-terms
                </li>
              </ul>
            </li>
          </ol>
        </p>
        <h2>四、我们如何共享、转让、公开披露您的个人信息</h2>
        4.1 共享
        <p>
          我们不会向任何第三方公司，组织和个人共享您的个人信息，但以下情况除外：
          <ol>
            <li>
              获得您或您监护人的明确同意后，我们会与其他方共享您的个人信息；
            </li>
            <li>
              我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息；
            </li>
            <li>
              为提供、维护及改善我们的产品或服务向合作伙伴及其他第三方分享您的信息；
            </li>
            <li>
              在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他用户或社会公众利益、财产或安全免遭损害，我们可能与其他公司和组织交换信息；
            </li>
            <li>
              我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会缔结严格的保密协议，要求他们按照我们的说明，本隐私政策以及其他任何相关的保密和安全措施来处理个人信息，无权将共享的个人信息用于任何其他用途。
            </li>
          </ol>
        </p>
        4.2 转让
        <p>
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          <ol>
            <li>
              在获取您或您的监护人明确同意的情况下转移，我们会向其他方转让您的个人信息；
            </li>
            <li>
              在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
            </li>
          </ol>
        </p>
        4.3 公开披露
        <p>
          我们仅会在以下情况下，公开披露您的个人信息：
          <ol>
            <li>获得您或您的监护人明确同意后；</li>
            <li>
              根据法律的披露：在法律，法律程序，诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
            </li>
          </ol>
        </p>
        4.4 例外情形
        <p>
          您的知悉并同意在以下情况下，我们共享、转让、公开披露个人信息可无需事先征得您的授权同意：
          <ol>
            <li>与国家安全、国防安全直接相关的；</li>
            <li>与公共安全、公共卫生、重大公共利益直接相关的；</li>
            <li>与犯罪侦查、诉讼、仲裁和判决执行等直接相关的；</li>
            <li>
              出于维护您或其他个人的生命、财产等重大合法权益但又碍难得到本人同意的；
            </li>
            <li>您自行向社会公众公开的个人信息；</li>
            <li>
              从合法公开披露的信息中收集个人信息的，如合法的新闻报道，政府信息公开等渠道。
            </li>
          </ol>
        </p>
        <h2>五、我们如何保护您的个人信息</h2>
        <p>
          您的个人信息安全对于我们至关重要。我们将严格遵守相关法律法规，采取业内认可的合理可行的措施，保护您的个人信息。防止信息遭到未经授权的访问、披露、使用、修改，避免信息损坏或丢失。
        </p>
        5.1 技术措施与数据安全措施
        <p>
          我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息安全。我们积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与我们提供的服务无关的个人信息。
        </p>
        <p>
          我们通过与信息接触者签署保密协议、监控和审计机制来对数据进行全面安全控制。防止您的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们已使用符合业界标准的安全防护措施保护您提供的个人、信息，防止数据遭到未经授权的访问、公开披露、使用、修改，防止数据发生损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
        </p>
        5.2 安全事件处置
        <p>
          我们将尽力确保您发送给我们的任何信息的安全性，但请您理解，由于技术的限制以及在互联网行业可能存在的各种恶意手段，不可能始终保证信息百分之百的安全。您需要了解，您接入我们服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。为防止安全事故的发生，我们制定了妥善的预警机制和应急预案。若不幸发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议和对您的补救措施，并立即启动应急预案，力求将损失最小化。我们将及时将事件相关情况以电话、推送通知等方式告知您，难以逐一告知用户时，我们将会采取合理、有效的方式发布公告。
        </p>
        <p>
          同时，我们还将按照监管部门的相关要求，主动上报个人信息安全事件的处置情况，紧密配合政府机关的工作。
        </p>
        <h2>六、您管理个人信息的权利</h2>
        <p>我们将按照本协议所述，保证您对自己的个人信息行使以下权利：</p>
        <p>
          6.1
          访问及更正您的个人信息，法律法规规定的特例情况除外，您有权访问及更正您的个人信息。
        </p>
        6.2 删除您的个人信息
        <p>
          在以下高度中，您可以向我们提出删除个人信息的请求：
          <ol>
            <li>如果我们处理个人信息的行为违反法律法规；</li>
            <li>如果我们收集、使用您的个人信息却未征得您的同意；</li>
            <li>如果我们处理个人信息的行为违反了与您的约定；</li>
            <li>如果您不再使用我们的产品或服务，或您重新注册了账号；</li>
            <li>如果我们不再为您提供产品或服务。</li>
          </ol>
          当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。
        </p>
        6.3 响应您的请求
        <p>
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于无端重复，需要过多技术手段，给他人合法权益带来的风险或者非常不切实际的请求，我们可能会拒绝。在以下情况中，按照法律法规要求，我们将无法响应您的请求：
          <ol>
            <li>与国家安全、国防安全直接相关的；</li>
            <li>与公共安全、公共卫生，重大公共利益直接相关的；</li>
            <li>与犯罪侦查、诉讼、仲裁和判决执行等直接相关的；</li>
            <li>有充分证据表明您存在主观恶意或滥用权利的；</li>
            <li>
              响应您的请求将导致您或其他个人，组织的合法权益受到严重损害的；
            </li>
            <li>涉及商业秘密的。</li>
          </ol>
        </p>
        <h2>七、我们如何使用Cookie和同类技术</h2>
        7.1. Cookie
        <p>
          为确保网站正常运转，我们会在您的计算机或移动设备上存储称为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符、学习进度等数据。我们不会将Cookie用作本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都提供阻止Cookie的功能。但如果您这样做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
        </p>
        7.2 网站信标和预期标签
        <p>
          例如，我们向您发送的电子邮件可能包含链接至我们网站内容的地址链接。如果您单击该链接，我们则会跟踪首次点击，帮助我们了解您的产品或服务更好并改善用户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        </p>
        <h2>八、我们如何保护未成年人的个人信息</h2>
        <p>
          若您是18周岁以下的未成年人，在使用我们的产品或服务前应事先取得您监护人的同意，并在监护人陪同和指导下阅读本隐私权政策。如果我们发现在未事先获得监护人同意的情况下收集了未满14周岁未成年人的个人信息，我们将逐步删除相关数据。
        </p>
        <h2>九、如何更新与修改本隐私协议</h2>
        <p>
          9.1
          本隐私协议为《语有灵犀用户服务协议》的重要组成部分。语有灵犀保留不时更新或修改本隐私协议的权利。
        </p>
        <p>
          9.2
          未经您明确同意，我们不会削减您按照本隐私协议所应享有的权利。我们会通过公众号推送通知、弹窗形式等合理方式通知您，以便您能及时了解本隐私协议所做的任何变更。
        </p>
        <p>
          9.3
          若您不同意修改后的隐私协议，您有权并应立即停止使用语有灵犀的服务。如果您继续使用语有灵犀提供的服务，则视为您接受语有灵犀对本隐私协议相关条款所做的修改。
        </p>
        <h2>十、如何联系我们及纠纷解决</h2>
        <p>
          10.1
          如对本隐私协议或相关事宜有任何问题、意见或者建议，您可随时通过客服热线400-990-9669与语有灵犀取得联系。
        </p>
        <p>
          10.2
          如果您认为我们的个人信息处理行为损害了您的合法权益，您也可向有关政府部门进行反映。
        </p>
      </section>
    </article>
  </>
);
